import { ICategory } from '@giphy/js-fetch-api';
import { EnumToArray } from '@libs/helper/helper';
import { ILobbySection, TAB_SECTION, GAME_CATEGORY } from '@libs/interfaces';

export const LOBBY_SECTIONS: ILobbySection[] = [
  {
    section: TAB_SECTION.FAVOURITES,
    type: 'GAME_EVENT',
    label: 'Favourites',
    icon: 'star',
    games: [],
  },
  {
    section: TAB_SECTION.RECENT,
    type: 'GAME_EVENT',
    label: 'Recent',
    icon: 'recents',
    games: [],
  },
  {
    section: TAB_SECTION.HOT,
    type: 'GAME_EVENT',
    label: 'HG',
    icon: 'hot',
    games: [],
  },
  {
    section: TAB_SECTION.NEW,
    type: 'GAME_EVENT',
    label: 'NR',
    icon: 'new',
    games: [],
  },
  {
    section: TAB_SECTION.SLOTS,
    type: 'GAME_CATEGORY',
    label: 'slots',
    icon: 'slots',
    games: [],
  },
  {
    section: TAB_SECTION.TABLE,
    type: 'GAME_CATEGORY',
    label: 'TGG',
    icon: 'table',
    games: [],
  },
  {
    section: TAB_SECTION.LIVE,
    type: 'GAME_CATEGORY',
    label: 'LC',
    icon: 'live',
    games: [],
  },
  {
    section: TAB_SECTION.CRASH,
    type: 'GAME_CATEGORY',
    label: 'Crash',
    icon: 'crash',
    games: [],
  },
  {
    section: TAB_SECTION.DICE,
    type: 'GAME_CATEGORY',
    label: 'craps',
    icon: 'dices',
    games: [],
  },
  {
    section: TAB_SECTION.ROULETTE,
    type: 'GAME_CATEGORY',
    label: 'roulette',
    icon: 'roulette',
    games: [],
  },
];
const categories_icons_not_defined = ['table_game', 'lottery_style_game', 'dragontiger', 'dice_slot', 'multibj'];
export const ParseCategoryIcon = (cat: GAME_CATEGORY) => {
  const splitted = cat.split(' ');
  const r1 = splitted.join('_').toLowerCase();
  const resultado = r1.replace('/', '_');
  return resultado;
};
const parseCategories = (): Partial<ICategory>[] =>
  EnumToArray(GAME_CATEGORY).map((cat: GAME_CATEGORY) => {
    return {
      category: cat,
      label: `CASINO.${cat.toLocaleLowerCase()}`,
      icon: ParseCategoryIcon(cat),
    } as Partial<ICategory>;
  });

export const CATEGORIES: any[] = parseCategories();
